var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { absolute: "", color: "transparent", flat: "", height: "85" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-toolbar-title", {
                staticClass: "font-weight-light",
                domProps: { textContent: _vm._s(_vm.title) }
              }),
              _c("v-spacer"),
              _vm._l(_vm.items, function(item, i) {
                return _c(
                  "v-btn",
                  {
                    key: i,
                    staticClass: "hidden-sm-and-down",
                    attrs: { to: item.to, "min-height": "48", text: "" }
                  },
                  [
                    _c("v-icon", {
                      attrs: { left: "", size: "20" },
                      domProps: { textContent: _vm._s(item.icon) }
                    }),
                    _c("span", { domProps: { textContent: _vm._s(item.text) } })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }